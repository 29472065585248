exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-algemene-voorwaarden-tsx": () => import("./../../../src/pages/algemene-voorwaarden.tsx" /* webpackChunkName: "component---src-pages-algemene-voorwaarden-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-gebruiksaanwijzing-tsx": () => import("./../../../src/pages/gebruiksaanwijzing.tsx" /* webpackChunkName: "component---src-pages-gebruiksaanwijzing-tsx" */),
  "component---src-pages-huren-checkout-tsx": () => import("./../../../src/pages/huren/checkout.tsx" /* webpackChunkName: "component---src-pages-huren-checkout-tsx" */),
  "component---src-pages-huren-pakket-kiezen-tsx": () => import("./../../../src/pages/huren/pakket-kiezen.tsx" /* webpackChunkName: "component---src-pages-huren-pakket-kiezen-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-locaties-tsx": () => import("./../../../src/pages/locaties.tsx" /* webpackChunkName: "component---src-pages-locaties-tsx" */),
  "component---src-pages-over-ons-tsx": () => import("./../../../src/pages/over-ons.tsx" /* webpackChunkName: "component---src-pages-over-ons-tsx" */),
  "component---src-pages-pakketten-tsx": () => import("./../../../src/pages/pakketten.tsx" /* webpackChunkName: "component---src-pages-pakketten-tsx" */),
  "component---src-pages-playground-tsx": () => import("./../../../src/pages/playground.tsx" /* webpackChunkName: "component---src-pages-playground-tsx" */),
  "component---src-pages-verhuishulp-tsx": () => import("./../../../src/pages/verhuishulp.tsx" /* webpackChunkName: "component---src-pages-verhuishulp-tsx" */),
  "component---src-templates-display-product-tsx": () => import("./../../../src/templates/displayProduct.tsx" /* webpackChunkName: "component---src-templates-display-product-tsx" */)
}

